import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        loginPageFieldsBG: "#E9F0FD",
        blueColorVariant1: "#758AF8",
        blueColorVariant2: "#666CF7",
        blueColorVariant3: "#538CFF",
        blueColorVariant4: "#6699FF",
        blueColorVariant5: "#4D86F9",
        blueColorVariant6: "#588DF8",
        blueColorVariant7: "#8489f8",
        blueColorVariant8: "#3679FF",
        blueColorVariant9: "#2E4392",
        blueColorVariant10: "#0036b4",

        greyColorVariant1: "#858796",
        greyColorVariant2: "#896796",

        BlackColorVariant2: "#5A5C69",

        RedColorVariant1: "#c8492d",
      },
    },
  },
});
