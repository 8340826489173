<template>
  <v-app class="background-container" :style="backgroundStyle">
    <router-view />
  </v-app>
</template>

<script>
export default {
  computed: {
    backgroundStyle() {
      return {
        "background-size": "cover",
        "background-repeat": "no-repeat",
      };
    },
  },
};
</script>

<style>
.fontsize12px {
  font-size: 12px !important;
}
.fontsize13px {
  font-size: 13px !important;
}
.fontsize14px {
  font-size: 14px !important;
}
.fontsize15px {
  font-size: 15px !important;
}
.fontsize16px {
  font-size: 16px !important;
}
.fontsize17px {
  font-size: 17px !important;
}
.fontsize18px {
  font-size: 18px !important;
}
.fontsize20px {
  font-size: 20px !important;
}
.fontsize25px {
  font-size: 25px !important;
}
.fontsize30px {
  font-size: 30px !important;
}
.fontsize20px {
  font-size: 20px !important;
}
.fontsize20px {
  font-size: 20px !important;
}
.fontsize30px {
  font-size: 30px !important;
}
.fontsize32px {
  font-size: 32px !important;
}
.fontsize35px {
  font-size: 35px !important;
}
.fontsize40px {
  font-size: 40px !important;
}
.fontsize45px {
  font-size: 45px !important;
}
.fontsize50px {
  font-size: 50px !important;
}
.fontsize55px {
  font-size: 55px !important;
}
.fontsize60px {
  font-size: 60px !important;
}
.fontsize80px {
  font-size: 80px !important;
}
.fontsize120px {
  font-size: 120px !important;
}
.divborder {
  border: 1px solid grey !important;
}
.Imageradius {
  border-radius: 10px !important;
}
.line {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #33317a;
  transition: width 0.3s ease;
}
.scroll-button {
  z-index: 9999;
}
.line-visible {
  width: 100%;
}
.CursorPointer {
  cursor: pointer !important;
}
.divstyle {
  text-align: left !important;
  line-height: 1.6 !important;
  font-weight: 400 !important;
  color: #444 !important;
}
.icon-button {
  color: #2e4392 !important;
}

.icon-button:hover {
  color: #45c5ec !important;
}
.icon-Contact {
  color: #ffffff !important;
}

.icon-Contact:hover {
  color: #45c5ec !important;
}
.button-button {
  color: #000000 !important;
}

.button-button:hover {
  color: #2e4392 !important;
}
.button-container {
  display: flex !important;
  justify-content: space-between !important;
}
/* .radient-image {
  width: 100% !important;
  height: 50% !important;
  position: relative;
} */

.gradient-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.2)
  );
}
.image-container {
  position: relative;
}

.gradient-image {
  width: 100% !important;
  height: 100% !important;
}

.gradient-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(39, 49, 109, 0.872),
    rgba(39, 40, 94, 0.861)
  );
}
.center-btn {
  text-align: left !important;
  margin: 10px !important;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonRadius {
  border-radius: 0px !important;
}
.logo-container {
  position: fixed;
  top: 100px; /* Adjust this value to control the vertical position */
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999; /* Ensure the image is above the v-main content */
}

.text-lowercase {
  text-transform: lowercase;
}
.Safetytext {
  font-size: calc(16px + 6vw);
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
}
.marquee-container {
  overflow: hidden;
  /* white-space: nowrap; */
}

.marquee-content {
  display: inline-block;
  animation: slide 0.5s linear; /* Adjust animation duration as needed */
}
.press-content {
  display: inline-block;
  animation: sliding 0.5s linear; /* Adjust animation duration as needed */
}
.Apple-Slider {
  display: inline-block;
  animation: slider 0.5s linear; /* Adjust animation duration as needed */
}
.Text-Slider {
  display: inline-block;
  animation: Slider 0.5s linear; /* Adjust animation duration as needed */
}

@keyframes Slider {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes slider {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes sliding {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slide {
  from {
    transform: translatey(50%);
  }
  to {
    transform: translatey(0%);
  }
}
.buttonStl {
  font-size: 18px !important;
  font-weight: 400 !important;
}
.slide-left {
  animation: slideFromLeft 1s ease-in-out;
}

.slide-right {
  animation: slideFromRight 1s ease-in-out;
}
.slide-top {
  animation: slideFromTop 1s ease-in-out;
}
@keyframes slideFromLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

/* Animation for sliding from the right */
@keyframes slideFromRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideFromTop {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0);
  }
}
.prev-icon {
  position: absolute !important;
  top: 50%;
  left: 20px;
  cursor: pointer;
}

.next-icon {
  position: absolute !important;
  top: 50%;
  right: 20px;
  cursor: pointer;
}
</style>
