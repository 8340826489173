import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../views/MainLandingPages/LandingPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
    children: [
      {
        path: "/home",
        name: "HomePage",
        component: () =>
          import(
            /* webpackChunkName: "LawEnforcement" */ "../views/ChildrenHomePages/HomePage.vue"
          ),
      },
      {
        path: "/Product",
        name: "ProductPage",
        component: () =>
          import(
            /* webpackChunkName: "UsersPage" */ "../views/ChildrenHomePages/ProductPage.vue"
          ),
      },

      {
        path: "/Press",
        name: "Press",
        component: () =>
          import(
            /* webpackChunkName: "AdminUsers" */ "../views/ChildrenHomePages/PressPage.vue"
          ),
      },
      {
        path: "/Contact",
        name: "Contact",
        component: () =>
          import(
            /* webpackChunkName: "Violations" */ "../views/ChildrenHomePages/ContactPage.vue"
          ),
      },
      {
        path: "/Privacy",
        name: "Privacy",
        component: () =>
          import(
            /* webpackChunkName: "Violations" */ "../views/ChildrenHomePages/PrivacyPolicy.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
