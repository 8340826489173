<template>
  <v-app>
    <v-container fluid fill-height class="pa-0">
      <v-layout align-center justify-center>
        <v-flex>
          <v-toolbar
            dense
            fixed
            color="transparent"
            :height="$vuetify.breakpoint.lg ? '120px' : '100px'"
            class="elevation-0"
          >
            <v-img
              src="@/assets/MIHSlogo.png"
              :height="$vuetify.breakpoint.lg ? '120px' : '100px'"
              max-width="140px"
              contain
              class="pa-2"
            ></v-img>
            <v-spacer />
            <div v-if="$vuetify.breakpoint.lg">
              <v-btn
                text
                depressed
                v-for="(item, index) in RouterList"
                :key="index"
                class="buttonStl pa-3"
                @click="navigateTo(item), (bton = item)"
                :color="isActive(item) ? 'blueColorVariant9' : 'black'"
                @mouseenter="animateLineIn(index)"
                @mouseleave="animateLineOut"
                >{{ item.MenuName }}
                <div
                  class="line"
                  :class="{
                    'line-visible':
                      isActive(item) ||
                      (isLineVisible && hoveredIndex === index),
                  }"
                  ref="line"
                ></div
              ></v-btn>
            </div>
            <v-app-bar-nav-icon
              @click.stop="drawer = !drawer"
              class="ma-4"
              x-large
              color="black"
              v-if="$vuetify.breakpoint.name != 'lg'"
            ></v-app-bar-nav-icon>
          </v-toolbar>

          <v-main>
            <router-view />
          </v-main>

          <v-navigation-drawer
            v-if="$vuetify.breakpoint.name != 'lg'"
            v-model="drawer"
            :right="drawer == true"
            absolute
            temporary
            color="#ffffffcc"
          >
            <v-btn icon @click="drawer = false"
              ><v-icon color="black">mdi-close</v-icon></v-btn
            >
            <v-col align="center">
              <v-btn
                medium
                depressed
                v-for="item in RouterList"
                :key="item.MenuName"
                :color="isActive(item) ? 'blueColorVariant9' : 'black'"
                class="fontsize20px white--text my-2 buttonRadius"
                @click="navigateDrawerTo(item), (bton = item)"
                width="150px"
                dark
                >{{ item.MenuName }}</v-btn
              >
            </v-col>
          </v-navigation-drawer>

          <v-row justify="center" class="ma-3">
            <v-img
              class="ma-6 Imageradius Apple-Slider CursorPointer"
              src="@/assets/AppleImg.png"
              height="60px"
              max-width="250px"
              @click="AppleOpen()"
              contain
            ></v-img>
            <v-img
              class="ma-3 press-content CursorPointer"
              src="@/assets/PlayStoreImg.png"
              max-height="120px"
              max-width="220px"
              @click="PlayStoreOpen()"
              contain
            ></v-img>
          </v-row>
          <div class="ma-0">
            <v-img
              dark
              src="@/assets/PoliceGrad1.jpg"
              :height="$vuetify.breakpoint.lg ? '300' : '250'"
              v-if="$route.name == 'ProductPage'"
            >
              <div
                class="blueColorVariant9--text font-weight-bold Text-center ma-6"
                align="center"
                :class="
                  $vuetify.breakpoint.name == 'lg'
                    ? 'fontsize50px'
                    : 'fontsize35px'
                "
              >
                FEEL SAFE ON THE ROAD
                <span class="black--text"
                  >THROUGH OUR SECURITY<br v-if="$vuetify.breakpoint.lg" />
                  SOFTWARE</span
                >
              </div>
            </v-img>
          </div>
          <v-divider horizontal />
          <v-row class="ma-2" justify="center">
            <v-col cols="12" md="2" xs="12" sm="12" align="center">
              <v-img
                src="@/assets/MIHSlogo.png"
                height="140px"
                max-width="140px"
                contain
              ></v-img>
            </v-col>
            <v-divider vertical />
            <v-col cols="12" md="10" xs="12" sm="12">
              <v-card class="text-center elevation-0">
                <v-col cols="12" md="12" xs="12" sm="12">
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                      xs="12"
                      sm="12"
                      :class="
                        $vuetify.breakpoint.lg ? 'text-left' : 'text-center'
                      "
                    >
                      <a @click="CallNumber()" class="button-button"
                        ><v-icon color="blueColorVariant9">mdi-phone</v-icon
                        >(412) 818-9986</a
                      >
                    </v-col>
                    <v-col cols="12" md="4" xs="12" sm="12">
                      <a
                        @click="SendEmail()"
                        class="text-lowercase button-button fontsize17px"
                        ><v-icon color="blueColorVariant9">mdi-email</v-icon
                        >info@makeithomesafe.com</a
                      >
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      xs="12"
                      sm="12"
                      :class="
                        $vuetify.breakpoint.lg ? 'text-right' : 'text-center'
                      "
                    >
                      <v-btn
                        v-for="(item, i) in MIHSICONS"
                        :key="i.icon"
                        icon
                        @click="openWindow(item.link)"
                        class="icon-button"
                      >
                        <v-icon medium>{{ item.icon }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider horizontal class="ma-4" />
                <v-btn
                  text
                  medium
                  depressed
                  plain
                  v-for="(item, btn_idx) in RouterList"
                  :key="btn_idx"
                  class="fontsize15px"
                  @click="navigateTo(item), (bton = item)"
                  :color="isActive(item) ? 'blueColorVariant9' : 'black'"
                  dark
                  >{{ item.MenuName }}</v-btn
                >
                <v-btn text @click="PrivacyMethod()" plain class="button-button"
                  >Privacy Policy</v-btn
                >
              </v-card>
            </v-col>
          </v-row>
          <v-card
            tile
            color="blueColorVariant9"
            height="50px"
            class="white--text fontsize13px pl-4 pt-4"
            :class="$vuetify.breakpoint.lg ? 'text-left' : 'text-center'"
          >
            © 2023 Make It Home Safe. All Rights Reserved.
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-btn
      v-show="showScrollButton"
      fab
      bottom
      right
      fixed
      class="scroll-button"
      @click="scrollToTop"
      color="grey darken-1"
      dark
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import RouterList from "@/JsonFiles/RouterList.json";
export default {
  data: () => ({
    transformStyle: "",
    showScrollButton: false,
    isLineVisible: false,
    hoveredIndex: null,
    peer: null,
    bton: {},
    button: "",
    drawer: false,
    RouterList: [],

    MIHSICONS: [
      {
        icon: "mdi-apple",
        link: "https://apps.apple.com/us/app/makeithomesafe-app/id6451196768",
      },
      {
        icon: "mdi-google-play",
        link: "https://play.google.com/store/apps/details?id=com.apps.makhs",
      },
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com/MakeithomesafeInc/",
      },
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/makeithomesafe1/",
      },
      {
        icon: "mdi-twitter",
        link: "https://twitter.com/i/flow/login?redirect_after_login=%2FMakeitHomeSafe1",
      },
      {
        icon: "mdi-linkedin",
        link: "https://www.linkedin.com/authwall?trk=gf&trkInfo=AQGwbw5vg134-gAAAYl4gZLoO7s3lWIRybVjH-ZsLAGxXdSVU34WnFKHJTTJF_l9cO619Hk1HSWWMosvMldWCmFzscbpz9x4o4Yv-ZmVQkXLF4i2e6wqVF9UFi8bDczemgXKgDw=&original_referer=https://makeithomesafe.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fmake-it-home-safe",
      },
    ],
  }),
  mounted() {
    this.RouterList = RouterList;

    this.updateActiveButton();
    this.HomePage();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    $route() {
      this.updateActiveButton();
    },
  },
  methods: {
    updateActiveButton() {
      const currentRoute = this.$route.path;
      this.bton = this.RouterList.find(
        (item) => item.MenuRoute === currentRoute
      );
    },
    animateLineIn(index) {
      this.isLineVisible = true;
      this.hoveredIndex = index;
    },
    animateLineOut() {
      this.isLineVisible = false;
      this.hoveredIndex = null;
    },
    HomePage() {
      if (this.$route.name == "Landing") {
        this.$router.push("/home");
      }
    },
    isActive(item) {
      return this.bton && this.bton.MenuName === item.MenuName;
    },
    SendEmail() {
      const recipientEmail = "info@makeithomesafe.com";
      const subject = "Subject :";
      const body = "";

      const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;

      window.location.href = mailtoLink;
    },
    CallNumber() {
      const phoneNumber = "+1(412) 818-9986";

      window.location.href = `tel:${phoneNumber}`;
    },

    navigateTo(item) {
      if (this.$route.path !== item.MenuRoute) {
        this.bton = item;
        this.$router.push(item.MenuRoute);
        this.scrollToTop();
      }
    },
    navigateDrawerTo(item) {
      if (this.$route.path !== item.MenuRoute) {
        this.$router.push(item.MenuRoute);
      }
    },
    PrivacyMethod() {
      this.$router.push("/Privacy");
      this.scrollToTop();
    },
    openWindow(link) {
      window.open(link, "_blank");
    },
    AppleOpen() {
      window.open(
        "https://apps.apple.com/us/app/makeithomesafe-app/id6451196768"
      );
    },
    PlayStoreOpen() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.apps.makhs"
      );
    },
    handleScroll() {
      if (window.scrollY > 0) {
        this.showScrollButton = true;
      } else {
        this.showScrollButton = false;
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped></style>
